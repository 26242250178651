@keyframes slideandappear {
  from {
    transform: translateY(16px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.pinnacle-pro-wrapper .title-active h3,
.pinnacle-pro-wrapper .comfort-active h3,
.pinnacle-pro-wrapper .ux-active h3 {
  animation: slideandappear 700ms ease-in-out forwards;
}

.pinnacle-pro-wrapper .title-active h5,
.pinnacle-pro-wrapper .ux-active h5,
.pinnacle-pro-wrapper .comfort-active .subtitle {
  animation: slideandappear 700ms ease-in-out forwards 80ms;
}

.pinnacle-pro-wrapper .user-experience-wrapper {
  background: url('../../assets/pinnaclePro/ux_bg.png'),
    linear-gradient(180deg, #2660dd 0%, #084de1 100%);
  background-size: cover, cover;
  background-position: center top, left top;
  background-repeat: no-repeat;
}

.pinnacle-pro-wrapper .subfooter-wrapper {
  background-image: url('assets/bg-overlay/ellipse-bg.webp'),
    linear-gradient(180deg, #2660dd 0%, #084de1 100%),
    linear-gradient(164deg, #f69a4d 11.1%, #ff7400 128.9%);
  background-position: top 20px center, top center, top center;
  background-size: 100% 100%, cover, cover;
  background-repeat: no-repeat;
}

.pinnacle-pro-wrapper .comfort-title-gradient {
  background-image: linear-gradient(113deg, #f69a4d 37.57%, #fff 194.48%);
}

.pinnacle-pro-wrapper .comfort-section-wrapper {
  background: radial-gradient(30% 65% at 90% 85%, #60656c9a, transparent),
    linear-gradient(180deg, #10171d 0%, #272e38 100%);
  background-position: right bottom, left top;
  background-size: cover, cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .pinnacle-pro-wrapper .comfort-section-wrapper {
    background: radial-gradient(100% 45% at 50% 10%, #60656c67, transparent),
      linear-gradient(180deg, #10171d 0%, #272e38 100%);
  }
}

/*  features grid */
.pinnacle-pro-wrapper .feature-grid {
  opacity: 0;
}

.pinnacle-pro-wrapper .feature-grid-active {
  opacity: 1;
  transition: opacity 150ms ease-in-out;
}

.pinnacle-pro-wrapper .feature-grid-exit {
  opacity: 1;
}

.pinnacle-pro-wrapper .feature-grid-exit-active {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.pinnacle-pro-wrapper .feature-grid-image-enter,
.pinnacle-pro-wrapper .feature-motion-wrapper .video-wrapper-enter {
  filter: blur(20px);
  opacity: 0;
}

.pinnacle-pro-wrapper .feature-grid-image-enter-active,
.pinnacle-pro-wrapper .feature-motion-wrapper .video-wrapper-enter-active {
  opacity: 1;
  filter: blur(0);
  transition: all 300ms ease-in-out;
}

.pinnacle-pro-wrapper .feature-grid-image-exit,
.pinnacle-pro-wrapper .feature-motion-wrapper .video-wrapper-exit {
  filter: blur(0);
  opacity: 1;
}

.pinnacle-pro-wrapper .feature-grid-image-exit-active,
.pinnacle-pro-wrapper .feature-motion-wrapper .video-wrapper-exit-active {
  transition: all 300ms ease-in-out;
  filter: blur(20px);
  opacity: 0;
}

.pinnacle-pro-wrapper .comfort-active .img-wrapper {
  animation: imageappear 300ms ease-in-out forwards 200ms;
}

.pinnacle-pro-wrapper .feature-grid-text-wrapper h2,
.pinnacle-pro-wrapper .feature-grid-text-wrapper h4,
.pinnacle-pro-wrapper .feature-motion-wrapper h4,
.pinnacle-pro-wrapper .feature-motion-wrapper h2,
.pinnacle-pro-wrapper .feature-grid-text-wrapper h3,
.pinnacle-pro-wrapper .feature-motion-wrapper h3 {
  animation: slideandappear 300ms ease-in-out forwards;
}

.pinnacle-pro-wrapper .feature-grid-text-wrapper h6,
.pinnacle-pro-wrapper .feature-motion-wrapper h6 {
  animation: slideandappear 300ms ease-in-out forwards;
  animation-delay: 100ms;
}

.pinnacle-pro-wrapper .feature-motion-wrapper span {
  animation: slideandappear 300ms ease-in-out forwards;
  animation-delay: 160ms;
}

.pinnacle-pro-wrapper .feature-grid-text-wrapper li:nth-child(1) {
  animation: slideandappear 300ms ease-in-out forwards;
  animation-delay: 200ms;
}

.pinnacle-pro-wrapper .feature-grid-text-wrapper li:nth-child(2),
.pinnacle-pro-wrapper .comfort-active .pointers .point:nth-child(2) {
  animation: slideandappear 300ms ease-in-out forwards;
  animation-delay: 250ms;
}

.pinnacle-pro-wrapper .feature-grid-text-wrapper li:nth-child(3),
.pinnacle-pro-wrapper .comfort-active .pointers {
  animation: slideandappear 300ms ease-in-out forwards;
  animation-delay: 300ms;
}

.pinnacle-pro-wrapper .feature-grid-text-wrapper li:nth-child(4),
.pinnacle-pro-wrapper .comfort-active .pointers .point:nth-child(4) {
  animation: slideandappear 300ms ease-in-out forwards;
  animation-delay: 300ms;
}

@keyframes imageappear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}