/* desktop */
@media (min-width: 1280px) {
  .header-shadow {
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
    box-shadow: 0px 0px 2px 2px rgba(246, 154, 77, 0.33);
    border: 1px solid rgba(255, 168, 95, 0.42);
  }

  .header-contact-shadow {
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
    box-shadow: rgba(0, 0, 0, 0.05) 2px 4px 10px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px, rgba(0, 0, 0, 0.06) 0px -0.5px;
  }
}

/* mobile */
@media (max-width: 1024px) {
  .mob-header-shadow {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
}