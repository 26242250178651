.user-experience-wrapper {
  background: linear-gradient(180deg, #10171d 0%, #272e38 100%);
}

.caths-landing-page .capacity-carousel {
  opacity: 0;
}

.caths-landing-page .capacity-carousel-active {
  opacity: 1;
  transition: opacity 150ms ease-in-out;
}

.caths-landing-page .capacity-carousel-exit {
  opacity: 1;
}

.caths-landing-page .capacity-carousel-exit-active {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.caths-landing-page .capacity-carousel-image-enter,
.caths-landing-page .feature-motion-wrapper .video-wrapper-enter {
  filter: blur(20px);
  opacity: 0;
}

.caths-landing-page .capacity-carousel-image-enter-active,
.caths-landing-page .feature-motion-wrapper .video-wrapper-enter-active {
  opacity: 1;
  filter: blur(0);
  transition: all 300ms ease-in-out;
}

.caths-landing-page .capacity-carousel-image-exit,
.caths-landing-page .feature-motion-wrapper .video-wrapper-exit {
  filter: blur(0);
  opacity: 1;
}

.caths-landing-page .capacity-carousel-image-exit-active,
.caths-landing-page .feature-motion-wrapper .video-wrapper-exit-active {
  transition: all 300ms ease-in-out;
  filter: blur(20px);
  opacity: 0;
}

.caths-landing-page .capacity-text-wrapper h2,
.caths-landing-page .capacity-text-wrapper h4,
.caths-landing-page .feature-motion-wrapper h4,
.caths-landing-page .capacity-text-wrapper h3,
.caths-landing-page .feature-motion-wrapper h3 {
  animation: appear 300ms ease-in-out forwards;
}

.caths-landing-page .capacity-text-wrapper h6,
.caths-landing-page .feature-motion-wrapper h6 {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 100ms;
}

.caths-landing-page .feature-motion-wrapper span {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 160ms;
}

.caths-landing-page .capacity-text-wrapper li:nth-child(1) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 200ms;
}

.caths-landing-page .capacity-text-wrapper li:nth-child(2) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 250ms;
}

.caths-landing-page .capacity-text-wrapper li:nth-child(3) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 300ms;
}

.caths-landing-page .capacity-text-wrapper li:nth-child(4) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 300ms;
}

.caths-landing-page .capacity-text-wrapper li:nth-child(5) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 350ms;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.design-feature-carousel .swiper-pagination-bullet {
  background: #fff !important;
  opacity: 0.5 !important;
}

.design-feature-carousel .swiper-pagination-bullet-active {
  background: #f69a4d !important;
  opacity: 1 !important;
}

/* Infntite carousel */
/* .marquee-parent {
  mask-image: linear-gradient(to right, transparent 0%, #000 20%, #000 80%, transparent 100%);
} */

.marquee-cath {
  animation: scroll-x 5s linear forwards;
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }

  to {
    transform: translate(calc(-50%));
  }
}

.tabs-progress-wrapper .tab-00 {
  transform: translateX(0%);
}

.tabs-progress-wrapper .tab-01 {
  transform: translateX(113%);
}

.tabs-progress-wrapper .tab-02 {
  transform: translateX(215%);
}

.tabs-progress-wrapper .tab-03 {
  transform: translateX(318%);
}

.tabs-progress-wrapper .tab-04 {
  transform: translateX(420%);
}
.tabs-progress-wrapper .tab-05 {
  transform: translateX(525%);
}
