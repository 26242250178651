.diamond-back-banner {
  background: linear-gradient(180deg, #a9e0a1, #cddbca), #fff;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.classic-crown {
  background: linear-gradient(180deg, #0b161f 0%, #272d36 100%),
    linear-gradient(180deg, #10171d 0%, #272e38 100%), #fff;
}


.accessories-wrapper .accessories-img-enter {
  filter: blur(20px);
  opacity: 0;
}

.accessories-wrapper .accessories-img-enter-active {
  opacity: 1;
  filter: blur(0);
  transition: all 300ms ease-in-out;
}

.accessories-wrapper .accessories-img-exit {
  filter: blur(0);
  opacity: 1;
}

.accessories-wrapper .accessories-img-exit-active {
  transition: all 300ms ease-in-out;
  filter: blur(20px);
  opacity: 0;
}

.accessories-wrapper .accessories-details {
  opacity: 0;
}

.accessories-wrapper .accessories-details-active {
  opacity: 1;
  transition: opacity 150ms ease-in-out;
}

.accessories-wrapper .accessories-details-exit {
  opacity: 1;
}

.accessories-wrapper .accessories-details-exit-active {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.accessories-wrapper .accessories-text-wrapper h4 {
  animation: appear 300ms ease-in-out forwards;
}

.accessories-wrapper .accessories-text-wrapper h6 {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 100ms;
}

.mechanism-wrapper {
  background: linear-gradient(180deg,
      rgba(205, 219, 202, 0.4) 0%,
      rgba(169, 224, 161, 0.4) 100%),
    #fff;

  background-size: cover;
  background-repeat: no-repeat;
}

.mechanism-wrapper .dash-line {
  background-image: linear-gradient(to bottom,
      transparent,
      transparent 50%,
      #cff0ca 50%,
      #cff0ca 100%);
  background-color: #bbbbbb;
  background-size: 100% 10px, 100% 100%;
  border: none;
  background-repeat: repeat;
}

.crown-technical-specification {
  background-color: #f1f5f6;
}

.crown-technical-specification .crown-technical-specification-img-enter {
  filter: blur(20px);
  opacity: 0;
}

.crown-technical-specification .crown-technical-specification-img-enter-active {
  opacity: 1;
  filter: blur(0);
  transition: all 300ms ease-in-out;
}

.crown-technical-specification .crown-technical-specification-img-exit {
  filter: blur(0);
  opacity: 1;
}

.crown-technical-specification .crown-technical-specification-img-exit-active {
  transition: all 300ms ease-in-out;
  filter: blur(20px);
  opacity: 0;
}

@media (max-width: 767px) {
  .accessories-wrapper {
    background-position: right 80% center;
  }
}