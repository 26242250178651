.blue-card-gradient {
  background-image: url('assets/awardsAndrecognitions/Excellence-colour-svg.svg');
  background-size: cover;
  background-position: center;
}


.div1 {
  grid-area: 1 / 1 / 2 / 3;
}

.div2 {
  grid-area: 1 / 3 / 2 / 4;
}