.blur-gradient-stats::after {
  content: '';
  border-radius: 0.375rem;
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  z-index: -1;
  background: linear-gradient(314deg,
      rgba(133,
        137,
        143, 1) -1.72%,
      rgba(80,
        94,
        123, 1) 100%);
  /* background: linear-gradient(314deg,
      rgba(255, 255, 255, 0.35) -1.72%,
      rgba(153, 184, 255, 0.35) 100%); */
}