/* Balloon capacity carousels */

.pinnacle-pages .products-tab-carousel .swiper-slide {
  height: auto !important;
}

.pinnacle-pages .products-tab-carousel .capacity-carousel {
  opacity: 0;
}

.pinnacle-pages .products-tab-carousel .capacity-carousel-active {
  opacity: 1;
  transition: opacity 150ms ease-in-out;
}

.pinnacle-pages .products-tab-carousel .capacity-carousel-exit {
  opacity: 1;
}

.pinnacle-pages .products-tab-carousel .capacity-carousel-exit-active {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.pinnacle-pages .products-tab-carousel .capacity-carousel-image-enter {
  filter: blur(20px);
  opacity: 0;
}

.pinnacle-pages .products-tab-carousel .capacity-carousel-image-enter-active {
  opacity: 1;
  filter: blur(0);
  transition: all 300ms ease-in-out;
}

.pinnacle-pages .products-tab-carousel .capacity-carousel-image-exit {
  filter: blur(0);
  opacity: 1;
}

.pinnacle-pages .products-tab-carousel .capacity-carousel-image-exit-active {
  transition: all 300ms ease-in-out;
  filter: blur(20px);
  opacity: 0;
}

.pinnacle-pages .products-tab-carousel .transition-text-wrapper :nth-child(1) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 100ms;
}

.pinnacle-pages .products-tab-carousel .transition-text-wrapper :nth-child(2) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 150ms;
}

.pinnacle-pages .products-tab-carousel .transition-text-wrapper :nth-child(3) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 200ms;
}

.pinnacle-pages .products-tab-carousel .transition-text-wrapper :nth-child(4) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 250ms;
}

.pinnacle-pages .products-tab-carousel .transition-text-wrapper :nth-child(5) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 300ms;
}

.pinnacle-pages .products-tab-carousel .capacity-text-wrapper li:nth-child(1) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 200ms;
}

.pinnacle-pages .products-tab-carousel .capacity-text-wrapper li:nth-child(2) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 250ms;
}

.pinnacle-pages .products-tab-carousel .capacity-text-wrapper li:nth-child(3) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 300ms;
}

.pinnacle-pages .products-tab-carousel .capacity-text-wrapper li:nth-child(4) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 350ms;
}


@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}