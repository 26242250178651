.stent-diameter {
  background-image: linear-gradient(
    123deg,
    #fec18f 30.5%,
    rgba(246, 154, 77, 0) 149.8%
  );
  background-color: #fff;
}

.stent-fixed-first-row {
}

.stent-first-column-color {
  background-image: linear-gradient(
    123deg,
    #fbd9ba 30.5%,
    rgba(246, 154, 77, 0) 149.8%
  );
  background-color: white;
}

.stent-fixed-first-column > th:first-child,
.stent-fixed-first-column > td:first-child {
  position: sticky;
  left: 0;
}
.stent-fixed-row {
  position: sticky;
  left: 0;
}

.stent-length-scrollbar::-webkit-scrollbar {
  height: 4px !important;
}

.stent-length-scrollbar::-webkit-scrollbar-thumb {
  background-color: #f69a4d !important;
}

.stent-length-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #f69a4d !important;
}

.stent-length-scrollbar::-webkit-scrollbar-track {
  background-color: #d8d8d8 !important;
}

.stent-length-scrollbar::-webkit-scrollbar-track:hover {
  background-color: #d8d8d8 !important;
}
