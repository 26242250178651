.user-experience-wrapper {
  background: linear-gradient(180deg, #10171d 0%, #272e38 100%);
}

.premier-elite-wrapper .swiper-slide,
.enhancement-carousel .swiper-slide {
  height: auto !important;
}

.premier-elite-wrapper .capacity-carousel {
  opacity: 0;
}

.premier-elite-wrapper .capacity-carousel-active {
  opacity: 1;
  transition: opacity 150ms ease-in-out;
}

.premier-elite-wrapper .capacity-carousel-exit {
  opacity: 1;
}

.premier-elite-wrapper .capacity-carousel-exit-active {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.premier-elite-wrapper .capacity-carousel-image-enter,
.premier-elite-wrapper .feature-motion-wrapper .video-wrapper-enter {
  filter: blur(20px);
  opacity: 0;
}

.premier-elite-wrapper .capacity-carousel-image-enter-active,
.premier-elite-wrapper .feature-motion-wrapper .video-wrapper-enter-active {
  opacity: 1;
  filter: blur(0);
  transition: all 300ms ease-in-out;
}

.premier-elite-wrapper .capacity-carousel-image-exit,
.premier-elite-wrapper .feature-motion-wrapper .video-wrapper-exit {
  filter: blur(0);
  opacity: 1;
}

.premier-elite-wrapper .capacity-carousel-image-exit-active,
.premier-elite-wrapper .feature-motion-wrapper .video-wrapper-exit-active {
  transition: all 300ms ease-in-out;
  filter: blur(20px);
  opacity: 0;
}

.premier-elite-wrapper .capacity-text-wrapper h2,
.premier-elite-wrapper .capacity-text-wrapper h4,
.premier-elite-wrapper .feature-motion-wrapper h4,
.premier-elite-wrapper .feature-motion-wrapper h2,
.premier-elite-wrapper .capacity-text-wrapper h3,
.premier-elite-wrapper .feature-motion-wrapper h3 {
  animation: appear 300ms ease-in-out forwards;
}

.premier-elite-wrapper .capacity-text-wrapper h6,
.premier-elite-wrapper .feature-motion-wrapper h6 {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 100ms;
}

.premier-elite-wrapper .feature-motion-wrapper span {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 160ms;
}

.premier-elite-wrapper .capacity-text-wrapper li:nth-child(1) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 200ms;
}

.premier-elite-wrapper .capacity-text-wrapper li:nth-child(2) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 250ms;
}

.premier-elite-wrapper .capacity-text-wrapper li:nth-child(3) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 300ms;
}

.premier-elite-wrapper .capacity-text-wrapper li:nth-child(4) {
  animation: appear 300ms ease-in-out forwards;
  animation-delay: 300ms;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.design-feature-carousel .swiper-pagination-bullet {
  background: #fff !important;
  opacity: 0.3 !important;
}

.design-feature-carousel .swiper-pagination-bullet-active {
  background: #fff !important;
  opacity: 1 !important;
}

.premier-elite-wrapper .contact-info-wrapper {
  background-image: url('assets/bg-overlay/ellipse-bg.webp'),
    linear-gradient(136deg, #0f73ba 33.82%, #0036d6 135.84%);
  background-position: top 20px center, top center;
  background-size: 100% auto, cover;
  background-repeat: no-repeat;
}

.blur-gradient-elite::after {
  content: '';
  border-radius: 0.375rem;
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  z-index: -1;
  background: linear-gradient(314deg,
      rgba(108,
        153,
        223, 1) -1.72%,
      rgba(62,
        134,
        213, 1) 100%);
}
