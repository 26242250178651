.blur-gradient-feature::after {
    content: '';
    border-radius: 0.375rem;
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #fff;
    /* z-index: -1; */
    background: linear-gradient(315deg, rgba(255, 255, 255, 0.00) 0%, #FFF 99.82%);
    backdrop-filter: blur(37px);
    /* background: linear-gradient(314deg,
        rgba(255, 255, 255, 0.35) -1.72%,
        rgba(153, 184, 255, 0.35) 100%); */
}