.awards-grad-x {
  mask-image: linear-gradient(to right, transparent 0%, #000 5%, #000 95%, transparent 100%);
}

.awards-grad-y {
  mask-image: linear-gradient(to bottom, transparent 0%, #000 5%, #000 95%, transparent 100%);
}

.marquee-parent {
  mask-image: linear-gradient(to right, transparent 0%, #000 20%, #000 80%, transparent 100%);
}

.carousel-swiper .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}