.balloon-banner-wrapper :nth-child(1) {
  animation: slideandappear 700ms ease-in-out forwards;
  animation-delay: 80ms;
}

.balloon-banner-wrapper :nth-child(2) {
  animation: slideandappear 700ms ease-in-out forwards;
  animation-delay: 160ms;
}

.balloon-banner-wrapper :nth-child(3) {
  animation: slideandappear 700ms ease-in-out forwards;
  animation-delay: 240ms;
}

@keyframes slideandappear {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scaleandappear {
  from {
    transform: scale(0.98);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}
