.sapphire-fixed-first-column>th:first-child,
.sapphire-fixed-first-column>td:first-child {
  position: sticky;
  left: 0;
}


.sapphire-fixed-first-column:nth-of-type(odd)>td:first-child {
  background-color: #FEC89A;
}

.sapphire-fixed-first-column>th:first-child {
  background-color: #FEC89A;
}

.sapphire-fixed-first-column:nth-of-type(even)>td:first-child {
  background-color: #FEC89A;
}

.sapphire-fixed-first-column:first-child,
.sapphire-fixed-first-column:not(:last-child) {
  border-bottom: 1px solid rgba(77, 77, 77, 0.20);
  border-collapse: collapse;
}