.exceptional-page-gradient {
  background: linear-gradient(180deg, #10171d 0%, #272e38 100%);
}

.enhanced-page-scroll::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 10px;
  background-color: transparent;
}

.enhanced-page-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.enhanced-page-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #fff;
}

.information_gradient {
  background: linear-gradient(180deg, #2660dd 0%, #084de1 100%);
}

.premier-swiper .swiper-pagination-bullet {
  background-color: #ff9015;
}