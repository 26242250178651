@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  scroll-behavior: smooth;
  font-family: 'regular';
}

@layer components {
  .blade-top-padding {
    @apply pt-8 md:pt-10 lg:pt-12 xl:pt-16 2xl:pt-20
    /* needs to be changed*/;
  }

  .blade-top-padding-sm {
    @apply pt-4 md:pt-6 lg:pt-8 xl:pt-10 2xl:pt-14;
  }

  .blade-top-padding-lg {
    @apply pt-10 md:pt-12 lg:pt-16 xl:pt-20 2xl:pt-24;
  }

  .blade-bottom-padding {
    @apply pb-8 md:pb-10 lg:pb-12 xl:pb-16 2xl:pb-20
    /* needs to be changed*/;
  }

  .blade-bottom-padding-sm {
    @apply pb-4 md:pb-6 lg:pb-8 xl:pb-10 2xl:pb-14
    /* needs to be changed*/;
  }

  .blade-bottom-padding-lg {
    @apply pb-10 md:pb-12 lg:pb-16 xl:pb-20 2xl:pb-24;
    /* needs to be changed*/
  }

  .blade-top-margin {
    @apply mt-8 md:mt-10 lg:mt-12 xl:mt-16 2xl:mt-20;
  }

  .blade-top-margin-sm {
    @apply mt-4 md:mt-6 lg:mt-8 xl:mt-10 2xl:mt-14;
  }

  .blade-top-margin-lg {
    @apply mt-10 md:mt-12 lg:mt-16 xl:mt-20 2xl:mt-24;
  }

  .blade-bottom-margin {
    @apply mb-8 md:mb-10 lg:mb-12 xl:mb-16 2xl:mb-20;
  }

  .blade-bottom-margin-sm {
    @apply mb-4 md:mb-6 lg:mb-8 xl:mb-10 2xl:mb-14;
  }

  .blade-bottom-margin-lg {
    @apply mb-10 md:mb-12 lg:mb-16 xl:mb-20 2xl:mb-24;
  }

  .w-container-sm {
    @apply max-w-screen-2xl mx-auto w-11/12 md:w-10/12 px-3;
  }

  .w-container {
    @apply max-w-screen-2xl mx-auto md:w-11/12 lg:w-10/12 px-3;
  }

  .w-container-lg {
    @apply md:w-11/12 px-3 max-w-[1650px] mx-auto;
  }

  .w-container-xl {
    @apply md:w-11/12 px-3 xl:px-16 2xl:px-20 max-w-[1920px] mx-auto;
  }
}

@layer base {
  h1 {
    @apply text-4xl lg:text-5xl 2xl:text-6xl leading-tight lg:leading-snug 2xl:leading-snug;
  }

  h2 {
    @apply text-3xl xl:text-4xl 2xl:text-5xl  leading-tight lg:leading-snug 2xl:leading-snug;
  }

  h3 {
    @apply text-2xl lg:text-3xl 2xl:text-4xl leading-tight lg:leading-snug 2xl:leading-snug;
  }

  h4 {
    @apply text-2xl lg:text-[28px] 2xl:text-3xl  leading-tight lg:leading-snug 2xl:leading-snug;
  }

  h5 {
    @apply text-lg lg:text-xl 2xl:text-2xl leading-normal md:leading-normal lg:leading-normal 2xl:leading-normal;
  }

  h6 {
    @apply text-base lg:text-base 2xl:text-xl leading-normal md:leading-normal lg:leading-normal 2xl:leading-normal;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #040418;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #040418;
}

::-webkit-scrollbar-track {
  background-color: #d8d8d8;
}

::-webkit-scrollbar-track:hover {
  background-color: #d8d8d8;
}

/* remove blue active state on mobile*/
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.features-mobile-wrapper .swiper-slide,
.capacity-carousel-wrapper .swiper-slide {
  height: auto !important;
}
